<template>

  <div class="page" id="top">

    <site-top-panel/>

    <Header v-stickto/>
    <!-- breadcrumb -->

    <section class="breadcrumb-section" data-background="/static/images/section-bg/section-bg-4.jpg">
      <div class="container align-center">
        <nav class="breadcrumb">
          <router-link to="/">Home</router-link>
          <a>UGC NET JRF</a>
        </nav>
      </div>
    </section>

    <!-- /breadcrumb -->

    <hr class="mt-0 mb-0 alt-2"/>

    <!-- /page section -->

    <section class="small-section">
      <div class="container align-justify">


        <h3 class="section-title font-alt">UGC NET JRF
<!--          <span>We are Open for You</span>-->
        </h3>
        <div class="row align-justify">
          <div class="col-lg-3 mt-10 mt-md-30">
            <div class="exp-media">
              <img src="../../assets/images/portfolio/pancha-6.jpg" alt="UGC NET JRF-2">
            </div>
          </div>
          <div class="col-lg-9 align-justify">
            <p>Yoga was introduced as a subject in UGC NET JRF exam from January 2017 exam.Through this exam, various
              National Universities, such as IITs and other reputed organizations hire qualified candidates for the
              position of Assistant professor and Junior Research Fellowship ( JRF). </p>
            <br>
            <p>NAAHAM is offering online coaching for UGC NET and JRF in YOGA.
              Our eminent faculty is Dr.Rajitha Ajeesh who has an experience of 10 years in the field of Yoga and
              Ayurveda.</p>

            <router-link to="/#register" class="button small mt-10 mb-20">Register</router-link>
          </div>

        </div>
        <div class="row">
          <div class="col-md-12 col-xs-12 mt-4 mt-lg-5">

            <h3 class="serv-title font-alt align-center">Our services are</h3>

            <div class="row mb-20">
              <div class="col-md-12">
                <ul class="list-style-3 basic-pancha align-justify">
                  <ul class="list-style-3 basic-pancha align-justify">
                    <li>Daily online classes for paper 1 and 2</li>
                    <li>Daily patanjali yoga sutra chanting</li>
                    <li>Weekily online exams.</li>
                    <li>Study materials are provided</li>
                    <li>Medium of class is English.</li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Reference/>
      </div>


    </section>
    <!-- /page section -->

    <!-- footer -->

    <Footer/>

    <!-- / footer -->

    <!-- scroll-top -->

    <Scrolltop/>

    <!-- / scroll-top -->

  </div>

</template>

<script>
import Header from "@/components/Header";
import SiteTopPanel from "@/components/site-top-panel";
import Footer from "@/components/Footer";
import Scrolltop from "@/components/Scrolltop";
import Reference from "@/components/external/reference";


export default {
  name: "UGC-NET",
  components: {
    Reference,
    Scrolltop,
    Footer,
    Header,
    SiteTopPanel
  }
}
</script>

<style scoped>

</style>
