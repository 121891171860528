<template>

  <footer id="contact">
    <div class="footer-rect">
      <img src="../assets/images/lines/rectangle-3.png" alt>
    </div>

    <!-- footer top -->
    <div class="footer-section">
      <div class="container">
        <div class="row">

          <!-- footer about -->

          <div class="col-md-4 col-xs-12 mb-lg-30">
            <div class="footer-about align-center">
              <h3 class="footer-title font-alt">About Naaham</h3>
              <div class="img-line">
                <img src="../assets/images/lines/img-line-1.png" alt>
              </div>
              <p class="mb-40 align-justify">‘Naham’ is a Sanskrit word (pronounced as 'Naaham'), a combination of two
                Sanskrit words i.e.
                ‘Na+Aham’. ‘Na’
                means ‘Not’ and ‘Aham’ means ‘I’. Naaham helps you to wake up to a new life.</p>
              <ul class="d-flex justify-content-center list-unstyled m-0 social-media">
                <li class="sub-nav-link">
                  <a href="https://www.facebook.com/Naaham-104204515082127" target="_blank">
                    <i class="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li class="sub-nav-link">
                  <a href="https://twitter.com/Naaham2" target="_blank">
                    <i class="fa fa-twitter"></i>
                  </a>
                </li>
                <li class="sub-nav-link">
                  <a href="https://www.instagram.com/naaham_yoga/" target="_blank">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
                <li class="sub-nav-link">
                  <a href="https://www.youtube.com/channel/UCtFxSuH-3mCKfiQxoL5gPqw/" target="_blank">
                    <i class="fa fa-youtube-play"></i>
                  </a>
                </li>
              </ul>

            </div>
          </div>

          <!-- / footer about -->

          <!-- footer tweets -->


          <div class="col-md-4 col-xs-12 mb-lg-30">

          </div>
          <!-- / footer tweets -->

          <!-- footer contacts -->

          <div class="col-md-4 col-xs-12 mb-lg-30">
            <div class="footer-contact align-center">
              <h3 class="footer-title font-alt">Contacts</h3>
              <div class="img-line">
                <img src="../assets/images/lines/img-line-1.png"
                     data-at2x="/static/images/lines/img-line-1@2x.png" alt>
              </div>
              <p>Dr. Rajitha Ajeesh <br>
                Arakkal house, Souparnika <br>
                Peringavu p.o, Thrissur <br>
                Kerala, India - 680008</p>
              E-mail: <a href="mailto:krajithaajeesh@gmail.com" target="_blank"
                         class="mail">krajithaajeesh@gmail.com</a><br/>
              Tel: <a href="tel:+91 95268 98900">+91 95268 98900</a><br/>
            </div>
          </div>

          <!-- / footer contacts -->

        </div>
      </div>
    </div>

    <!-- / footer top -->

    <!-- footer bottom -->

    <div class="bottom-footer">
      <div class="container">

        <!-- footer copy -->

        <div class="footer-copy">


          2020 @ Copyright naaham.in | Alright Reserved | Designed by <a class="" href="https://xeoscript.com/"
                                                                         target="_blank">Xeoscript Technologies</a>
          <span class="hidden">Terms of Use</span>
          <span class="hidden">Privacy Policy</span>
        </div>

        <!-- / footer copy -->

        <!-- footer social -->

        <div class="footer-soc-links hidden">
          <a href="#">
            <i class="flaticon-social"></i>
          </a>
          <a href="#">
            <i class="flaticon-social-4"></i>
          </a>
          <a href="#">
            <i class="flaticon-social-5"></i>
          </a>
          <a href="#">
            <i class="flaticon-connection"></i>
          </a>
          <a href="#">
            <i class="flaticon-social-2"></i>
          </a>

        </div>

        <!-- / footer social -->

      </div>
    </div>

    <!-- / footer bottom -->

  </footer>

</template>

<script>
export default {
  name: "footer"
}
</script>

<style scoped>

</style>