<template>
  <swiper class="swiper home-slider" :options="swiperOption">
    <swiper-slide>
      <div class="item">
        <img class="slider-img" src='../assets/images/section-bg/slider-1.jpg' alt="yoga Training1">
        <div class="tp-caption sl-content sl-content-left font-alt">
          <div class="sl-title font-alt">Therapeutic <br/> Yoga <br/> Practices</div>
          <div class="img-line">
            <img src="../assets/images/lines/img-line-8.png"
                 data-at2x="../assets/images/lines/img-line-8@2x.png" alt>
          </div>
        </div>
        <div class="tp-caption sl-content sl-content-right font-alt">
          <div class="sl-title font-alt">Meditation <br/>Techniques</div>
          <div class="img-line">
            <img src="../assets/images/lines/img-line-8.png"
                 data-at2x="../assets/images/lines/img-line-8@2x.png" alt>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="item">
        <img class="slider-img" src="../assets/images/section-bg/slider-2.jpg" alt="yoga Training2">
        <div class="tp-caption sl-content sl-content-left font-alt">
          <div class="sl-title font-alt">Breathing <br/> Exercises</div>
          <div class="img-line">
            <img src="../assets/images/lines/img-line-8.png"
                 data-at2x="../assets/images/lines/img-line-8@2x.png" alt>
          </div>
        </div>
        <div class="tp-caption sl-content sl-content-right font-alt">
          <div class="sl-title font-alt">Teaching <br>Yoga <br/> Asanas</div>
          <div class="img-line">
            <img src="../assets/images/lines/img-line-8.png"
                 data-at2x="../assets/images/lines/img-line-8@2x.png" alt>
          </div>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="item">
        <img class="slider-img" src="../assets/images/section-bg/slider-3.jpg" alt="yoga Training3">
        <div class="tp-caption sl-content sl-content-left font-alt">
          <div class="sl-title font-alt"> Remedies for <br/>Life style <br/> diseases</div>
          <div class="img-line">
            <img src="../assets/images/lines/img-line-8.png"
                 data-at2x="../assets/images/lines/img-line-8@2x.png" alt>
          </div>
        </div>
        <div class="tp-caption sl-content sl-content-right font-alt">
          <div class="sl-title font-alt">Treatments for <br>stress & <br/> Anxiety</div>
          <div class="img-line">
            <img src="../assets/images/lines/img-line-8.png"
                 data-at2x="../assets/images/lines/img-line-8@2x.png" alt>
          </div>
        </div>
      </div>
    </swiper-slide>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>


<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'


export default {
  name: "Home-banner",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        centeredSlides: true,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        }
      }
    }
  }
}
</script>

<style>

.home-slider .swiper-button-prev,
.home-slider .swiper-button-next {
  display: none !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}


.home-slider:hover .swiper-button-prev,
.home-slider:hover .swiper-button-next {
  display: block !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}


.swiper-button-next, .swiper-button-prev {
  font-size: 23px;
  color: #ffffff;
  width: 38px !important;
  height: 38px !important;
  background-color: #446713;
  -webkit-box-shadow: 0 0 0 4px #f7f5ef inset, 0 0 0 1px #c7d5d3;
  -moz-box-shadow: 0 0 0 4px #f7f5ef inset, 0 0 0 1px #c7d5d3;
  box-shadow: 0 0 0 4px #f7f5ef inset, 0 0 0 1px #c7d5d3;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 17px !important;
  color: white !important;
}

.slider-img{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
</style>