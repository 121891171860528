import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/css/vertical.min.css'
import './assets/css/ihover.css'
// import './assets/css/settings.css'
import './assets/css/styles.css'
import './assets/css/custom.css'
import 'font-awesome/css/font-awesome.min.css'
import VueTheMask from "vue-the-mask"

Vue.use(VueTheMask)
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)


import VueScrollTo from "vue-scrollto";

Vue.use(VueScrollTo)

import VueStickto from 'vue-stickto'

Vue.use(VueStickto)

Vue.config.productionTip = false
new Vue({
    router,
    created() {
        AOS.init()
    },
    render: h => h(App)
}).$mount('#app')



