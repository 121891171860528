<template>

  <div class="page" id="top">

    <site-top-panel/>

    <Header v-stickto/>
    <!-- breadcrumb -->

    <section class="breadcrumb-section">
      <div class="container align-center">
        <nav class="breadcrumb">
          <router-link to="/">Home</router-link>
          <a>Gynaecology</a>
        </nav>
      </div>
    </section>

    <!-- /breadcrumb -->

    <hr class="mt-0 mb-0 alt-2"/>

    <!-- /page section -->

    <section class="small-section">
      <div class="container align-justify">


        <h3 class="section-title font-alt">Gynaecology
          <span>(For Women)</span>
        </h3>
        <div class="row align-justify">
          <div class="col-lg-3 mt-10 mt-md-30">
            <div class="exp-media">
              <img src="../../assets/images/portfolio/pancha-5.jpg" alt="Gynaecology2">
            </div>
          </div>
          <div class="col-lg-9 align-justify">
            <p>Though ayurveda deals with wide spectrum of specialities , here we mainly concentrate on Yoga and
              Gynaecological disorders . We live in a world which is having high risk of Lifestyle disorders, lets join
              our hands to live a better life by simple practices such as making yoga part of our day to day life.</p>
            <br>
            <p> The rate of gynaecological disorders are increasing day by day, Menstrual disorders , pcos are starting
              to became "New normal" among the teenagers. Pcos, obesity, irregular periods, painful menstruation are
              some of the common problems faced by the youth, if it's ignored in the initial stages it may lead to
              further complications even resulting in Infertility. Such situations can be easily tackled with the help
              of "YOGA & AYURVEDA" under proper guidance. Let's practice healthy habit at a early stage before its too
              late. </p>
            <router-link to="/#register" class="button small mt-10 mb-20">Register</router-link>
          </div>

        </div>
        <div class="clearfix"></div>


        <div class="row">
          <div class="col-md-12 col-xs-12 mt-4 mt-lg-5">
            <h3 class="serv-title font-alt align-center">Types of treatment we provide</h3>

            <div class="row mb-20">
              <div class="col-md-12">
                <ul class="list-style-3 basic-pancha align-justify">
                  <li>
                    <p><b>Medications</b></p>
                    <p>Internally we advise various types of formulations which are taken from vedic textbooks as per
                      the condition. After analysing the patients condition, if it deamands We also provide -
                      "PANCHAKARMA THERERAPY" Such as:
                    </p>
                    <ul class="list-style-3 basic-pancha align-justify">
                      <li>Nasya(nasal medication)</li>
                      <li>Vamana (emesis)</li>
                      <li>Virechana (purgatio)</li>
                      <li>Vasthi (enema)</li>
                    </ul>
                    <p>Diet plan is also advised along with medications.</p>
                    <p>We conduct various "YOGA SESSION" FOR STRESS MANAGEMENT and also for GYNAECOLOGICAL DISORDERS
                      (such as PCOS, Menstrual disorsers, obesity). Apart from diseased conditions you can also seek
                      advices regarding "Ante Natal & Post Natal care" including Diet regimen, medications , yoga.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <Reference/>

      </div>

    </section>
    <!-- /page section -->

    <!-- footer -->

    <Footer/>

    <!-- / footer -->

    <!-- scroll-top -->

    <Scrolltop/>

    <!-- / scroll-top -->

  </div>

</template>

<script>
import Reference from "@/components/external/reference";
import Header from "@/components/Header";
import SiteTopPanel from "@/components/site-top-panel";
import Footer from "@/components/Footer";
import Scrolltop from "@/components/Scrolltop";


export default {
  name: "Gynaecology",
  components: {
    Scrolltop,
    Footer,
    Reference,
    Header,
    SiteTopPanel
  }
}
</script>

<style scoped>

</style>
