<template>

  <section class="page-section">
    <div class="container align-center">
      <h3 class="section-title bottom-line font-alt">Yoga Classes & Practice Sessions</h3>
      <div class="col-md-10 offset-md-1 mb-30">
        <p>Morning & Evening Batches | Weekend Special Batch | Yoga for Students</p>
      </div>
      <div class="clearfix"></div>

      <swiper class="swiper pb-50" :options="swiperOption">
        <swiper-slide>
          <div class="item">
            <div class="shop-container align-center alt-2">
              <a>
                <div class="shop-media">
                  <div class="shop-img">
                    <img src="../assets/images/portfolio/batch-1.png" alt="Morning & Evening Batches">
                  </div>
<!--                  <div class="label-new label-left font-alt">New</div>-->
                </div>
              </a>
              <h3 class="shop-title font-alt">Morning & Evening Batches</h3>
              <a v-scroll-to="'#register'" href="#" class="button small mt-20">Register</a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div class="shop-container align-center alt-2">
              <a>
                <div class="shop-media">
                  <div class="shop-img">
                    <img src="../assets/images/portfolio/batch-2.png" alt="Weekend Special Batch">
                  </div>
                </div>
              </a>
              <h3 class="shop-title font-alt">Weekend Special Batch</h3>
              <a v-scroll-to="'#register'" href="#" class="button small mt-20">Register</a>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div class="shop-container align-center alt-2">
              <a>
                <div class="shop-media">
                  <div class="shop-img">
                    <img src="../assets/images/portfolio/batch-3.png" alt="Yoga for Students">
                  </div>
                </div>
              </a>
              <h3 class="shop-title font-alt">Yoga for Students</h3>
              <a v-scroll-to="'#register'" href="#" class="button small mt-20">Register</a>
            </div>
          </div>
        </swiper-slide>

      </swiper>


    </div>
  </section>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'


export default {
  name: "Session",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 10,
        // loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // autoplay: {
        //   delay: 4500,
        //   disableOnInteraction: false
        // },
        breakpoints: {
          1200: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>