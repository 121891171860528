<template>
  <div @click="scrollTop" v-show="visible" id="scroll-top">
    <i class="fa fa-angle-up"></i>
    top
  </div>
</template>

<script>
export default {
  name: "Scrolltop",
  data() {
    return {
      visible: false
    }
  },
  methods: {
    scrollTop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 10)
    },
    scrollListener: function (e) {
      this.visible = window.scrollY > 150
    }
  },
  mounted: function () {
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.scrollListener)
  }
}


</script>

<style scoped>
#scroll-top {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  background-color: #ebe6d2;
  text-transform: uppercase;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0 0 0 1px #fff inset, 0 0 0 3px #ebe6d2;
  -moz-box-shadow: 0 0 0 1px #fff inset, 0 0 0 3px #ebe6d2;
  box-shadow: 0 0 0 1px #fff inset, 0 0 0 3px #ebe6d2;
}

#scroll-top > i {
  margin-top: 5px;
  display: block;
}
</style>