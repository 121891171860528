<template>


  <section class="page-section alt-color">
    <div class="container align-center pt-90" id="register">
      <h3 class="section-title bottom-line long font-alt">Register</h3>
      <div class="col-md-10 offset-md-1 mb-30">
        <p class="mb-0"><b>Register for free online yoga taster session.</b></p>
        <p class="mb-0">Time slots for different countries are available according to your requirements</p>
      </div>
      <div class="row mt-30">

        <div class="col-md-8 offset-md-2 mb-md-30">
          <div class="">
            <transition name="fade" mode="out-in">

              <div class="alert alert-success mb-0 w-100 text-center my-5" v-if="state === 'S'" key="'S">
                <p class="my-5">The mail has been successfully submitted.</p>
              </div>

              <div class="alert alert-danger mb-0 w-100 text-center my-5" v-else-if="state === 'F'" key="'F">
                <p class="my-5">The mail delivery has been failed.</p>
              </div>

              <div class="alert alert-info mb-0 w-100 text-center my-5" v-else-if="state === 'L'" key="'L">
                <p class="my-5">Please wait while communicate with the server</p>
              </div>

              <form v-else @submit.prevent="onSubmit">
                <input id="name" name="name" type="text" value="" size="30" required
                       placeholder="Your Name"  v-on:keypress="isLetter($event)"
                       v-model="name" class="input-md form-control text-capitalize">


                <input id="phone_number" name="phone_number" required
                       placeholder="Whatsapp number with Country code"
                       class="input-md form-control"
                       type="number" v-model="phone">

                <input id="country" name="country" type="text" required
                       placeholder="Country" v-mask="'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'"
                       class="input-md form-control country-input text-capitalize" v-model="country">
                <input id="state" name="state" type="text" required
                       placeholder="State" v-mask="'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa'"
                       class="input-md form-control state-input text-capitalize" v-model="states">
                <input id="email" name="email" type="email" value="" size="30" aria-required="true"
                       placeholder="Your E-Mail" class="input-md form-control" required v-model="email">
                <div class="selection-box ">
                  <select title="" name="treatment" aria-label="Treatment & Service" id="treatment"
                          class="input-md form-control " v-model="service" required>
                    <option value="">Select a Session</option>
                    <option value="Gynaecology">Gynaecology
                    </option>
                    <option value="UGC NET JRF">UGC NET JRF
                    </option>
                    <option value="Free Yoga Trial Session">Free Yoga Trial Session
                    </option>
                    <option value="Therapeutic Yoga">Therapeutic Yoga</option>
                    <option value="Yoga for Students">Yoga for Students</option>
                    <option value="Online Consultation Facilities">Online Consultation Facilities
                    </option>
                    <option value="Ayurveda Panchakarma Therapy">Ayurveda Panchakarma Therapy
                    </option>
                  </select>
                </div>

                <textarea placeholder="Your Message" name="message" cols="45" rows="8" id="msg"
                          class="input-md form-control mb-20" required v-model="message"
                          onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9 ]*$/, '')"></textarea>
                <div class="consent1 mb-20">
                  <input type="checkbox" id="consent" name="consent" @change="setMessage" v-model="consent_value">
                  <span><label>By checking this, I am allowing Naaham to contact me back if required.</label></span>
                </div>
                <button class="button small" value="Submit" id="submit" name="submit" type="submit">
                  Submit
                </button>
              </form>
            </transition>
          </div>
        </div>

      </div>
    </div>
  </section>


</template>

<script>

import axios from 'axios'

export default {
  name: "Register",
  data() {
    return {
      state: 'D',
      name: '',
      phone: '',
      country: '',
      states: '',
      service: '',
      email: '',
      message: '',
      consent_value: false,
      consent: 'I am not allowing Naaham to contact me back'
    }

  },

  methods: {
    onSubmit() {
      const bodyFormData = new FormData();
      bodyFormData.append('name', this.name);
      bodyFormData.append('phone', this.phone);
      bodyFormData.append('country', this.country);
      bodyFormData.append('states', this.states);
      bodyFormData.append('service', this.service);
      bodyFormData.append('email', this.email);
      bodyFormData.append('message', this.message)
      bodyFormData.append('consent', this.consent)

      const that = this;

      this.state = 'L';

      axios({
        method: "post",
        url: "https://naaham.in/mail.php",
        data: bodyFormData,
        headers: {"Content-Type": "multipart/form-data"},
      }).then(function (response) {
        that.onSubmitSuccess(response);
      }).catch(function (response) {
        that.onSubmitFailed(response);
      });
    },
    onSubmitSuccess() {
      this.state = 'S';
    },

    onSubmitFailed() {
      this.state = 'F';
    },
    setMessage() {
      console.log('this.consent_value =>', this.consent_value)
      if (this.consent_value === true) {
        this.consent = 'I am allowing Naaham to contact me back';
      } else {
        this.consent = 'I am not allowing Naaham to contact me back';
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z ]*$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    }
  }
}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>