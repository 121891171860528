<template>

  <div class="page" id="top">

    <site-top-panel/>

    <Header v-stickto/>
    <!-- breadcrumb -->

    <section class="breadcrumb-section">
      <div class="container align-center">
        <nav class="breadcrumb">
          <router-link to="/">Home</router-link>
          <a>Yoga for Students</a>
        </nav>
      </div>
    </section>

    <!-- /breadcrumb -->

    <hr class="mt-0 mb-0 alt-2"/>

    <!-- /page section -->

    <section class="small-section">
      <div class="container">


        <h3 class="section-title font-alt">Yoga for Students
          <span>(For Boys & Girls) </span>
        </h3>
        <div class="row align-justify">
          <div class="col-lg-3 mt-10 mt-md-30">
            <div class="exp-media">
              <img src="../../assets/images/portfolio/student-2.png" alt="Yoga for Students-6">
            </div>
          </div>
          <div class="col-lg-9 align-justify">
            <p> Good Health is the right of every human being. But this right depends on individual, social
              and environmental factors. Along with social or environmental factors to a large extent, we
              can develop a better immune system and a better perception of oneself so that other
              conditions do not affect us adversely and we can achieve good health.
            </p>
            <p> Health is a positive concept. Positive health does not mean merely freedom from disease,
              but, it also include a jubilant and energetic feeling of well-being with an amount of
              general resistance and capacity to easily cultivate immunity against specific offending
              agents.
            </p>
            <p>There are many modern and indigenous methods and disciplines that can help us to successfully
              fight with diseases. For example, the system of yoga, naturopathy, ayurveda, unani,
              homeopathy and siddha can be quoted among indigenous systems, whereas allopathic system is
              quoted as the modern and popular medical system. Yoga is one of the most powerful drugless
              systems of treatment.
            </p>
              <router-link to="/#register" class="button small mt-10 mb-20">Register</router-link>
          </div>

          <div class="clearfix"></div>
          <div class="col-12">
            <p> It is having its own concept of wellness which has been scientifically
              understood and presented by many. Yoga can be adopted as a lifestyle for promoting our
              physical and mental health. Yoga, if introduced at the school level would help to inculcate
              healthy habits and healthy lifestyle to achieve good health.</p>
            <p>The aim of yoga thus, at the school level, is to encourage a positive and healthy lifestyle for
              physical, mental and emotional health of childrens. Yoga helps in the development of strength,
              stamina, endurance and high energy at physical level. It also empowers oneself with increased
              concentration, calm, peace and contentment at mental level leading to inner and outer harmony.
            </p>
            <p>In Bhagavad-Gita yoga refers;</p>

            <p class="align-center font-alt"><i>
              ‘ Natyasnatastu yogosti na chikantam anasnath;
              Nacatisvapnasilasya jagrato naivacarjuna ‘
            </i></p>

            <p> That is “ yoga is not for one who overeats, neither for one who over fast or for these who sleep
              too much, and also not for one who over-wakes.
            </p>
            <p> Further in the Gita it signifies;</p>
            <p class="align-center font-alt"><i>
              “ Yaktaharaviharasya yukatacestasya karmasu,
              Yuktasvapnavabodhasyay
              Ogobhavati duhkhaha. “
            </i></p>
            <p> Yoga is for him who is moderate in eating and recreation, in actions, systematic in sleeping and
              working and more than that, it is yoga, which ultimately destroys all misery of human life.
            </p>
            <p>Most people are of the opinion that yoga refers to performing exercises to keep the body fit and
              trim. But it is more than that. The systematic yogic practices not only eliminate and control
              several diseases but also keep the mind perfect, clean and peaceful. That means, theyogic
              practice gives both physical and mental perfection.
            </p>
            <p>In the present difficult living situations, mental agitation, anxiety and depression are quite
              common. Everyone wants mental peace and satisfaction in his life. Now-a-days many money making
              organizations advertise regarding the conducting of classes on yoga, meditation and Pranic
              Healing etc. and claim that these are the sure methods to provide better health. But these
              institutions impart training for certain periods with an intention to earn money. The real
              object is to learn yoga and meditation systematically and to adopt them in lifestyle. Then only
              one can experience the real benefit of yoga and meditation.</p>
            <p> In Atharva Veda it is rightly said;</p>
            <p class="align-center font-alt"><i>
              “ O man, I yoke thy soul that goes to the next
              World through breath, with two carriers
              the prana and the Apana,
              Seek shelter under God and communion with Him ! ”
            </i></p>
            <p>That is, yoga not only provides good health but also provides control of mind, spiritual wisdom
              and self-realization.
            </p>
            <p> In the Gita, the concept of yoga reveals;</p>
            <p class="align-center font-alt"><i>
              “ Tam vidyat duhkhasamyoga-
              Viyogam yogasamjnitam;
              sa niscayena yoktavyo
              yogo nirvinnacetasa. “

            </i></p>
            <p> Yoga is a state of disunion from union with sorrow. The yoga has to be performed with utmost
              devotion, determination and undisturbed by depression. It is evident from the scores of studies
              conducted by several Universities and research organizations. They arrived at the conclusion
              that yogic practice helps to cure several diseases and to develop the concentration of mind and
              eases stress and tension. Unfortunately most of the people are unaware of the practices of yoga,
              that is why they are not in a position to get the benefits of yogic practices. Before practising
              the yogic exercises one has to keep his mind calm, determined and cool. This will be the
              foremost aspect to adopt before practicing yoga. In Bhagavad-Gita Krishna said to Arjuna,
            </p>
            <p class="align-center font-alt"><i>
              “The mind indeed is all you say Arjuna,
              But determination helps; and renunciation curbs it,
              Without determination, no man can reach yoga,
              But the self disciplined struggling nobly, can achieve it.”
            </i></p>
            <p>That is, the self-discipline, clean habits, self-control and determination are the important
              factors to keep in mind before practising yoga. The proper knowledge of yogic system,
              self-discipline, concentration of mind and more than that punctuality in performing yogic
              exercise are very important to achieve the benefits of yogic practices.
            </p>

            <Reference/>
          </div>
        </div>
      </div>


    </section>
    <!-- /page section -->

    <hr class="mt-0 mb-0 alt-2">

    <!-- footer -->
    <Footer/>
    <!-- / footer -->

    <!-- scroll-top -->

    <Scrolltop/>

    <!-- / scroll-top -->

  </div>

</template>

<script>
import Reference from "@/components/external/reference";
import Header from "@/components/Header";
import SiteTopPanel from "@/components/site-top-panel";
import Footer from "@/components/Footer";
import Scrolltop from "@/components/Scrolltop";


export default {
  name: "yoga-for-students",
  components: {
    Scrolltop,
    Footer,
    Reference,
    Header,
    SiteTopPanel
  }
}
</script>

<style scoped>

</style>
