<template>
  <header class="w-100">

    <nav class="navbar navbar-expand-lg sticky-header">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <i class="fa fa-bars"></i>
      </button>
      <div class="mob-nav-logo">
        <img src="../assets/images/logo1.png" alt="logo1">
      </div>
      <div class="inner-nav desktop-nav collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="clearlist navbar-nav">

          <template v-for="item in leftMenu">
            <li class="nav-item">
              <router-link v-scroll-to="item.div" :to="item.url + item.div" class="nav-link"
                           :class="{ 'active': active === item.text }"
                           @click="active = item.text" v-html="item.text"></router-link>
            </li>

            <li class="sep" v-if="!item.dontShowSeparator">
              <a class="nav-link">/</a>
            </li>
          </template>

          <li class="nav-logo-wrap">
            <router-link v-scroll-to="'#top'" to="/" href="#" class="logo">
              <img src="../assets/images/logo1.png" alt="logo2">
            </router-link>
          </li>

          <template v-for="item in rightMenu">
            <li class="nav-item">
              <router-link v-scroll-to="item.div" :to="item.url + item.div" class="nav-link"
                           :class="{ 'active': active === item.text }"
                           @click="active = item.text" v-html="item.text"></router-link>
            </li>

            <li class="sep" v-if="!item.dontShowSeparator">
              <a class="nav-link">/</a>
            </li>
          </template>

        </ul>
        <div class="menu-rect">
          <img src="../assets/images/lines/rectangle-1.png" alt>
        </div>
      </div>
    </nav>

  </header>
</template>

<script>
export default {
  data() {
    return {
      active: 'home',
      leftMenu: [
        {
          text: 'Home',
          url: '/',
          div: '#top'
        },
        {
          text: 'About',
          url: '/',
          div: '#about'
        },
        {
          text: 'Ayurveda',
          url: '/',
          div: '#ayurveda',
          dontShowSeparator: true
        },
      ],
      rightMenu: [
        {
          text: 'Yoga',
          url: '/',
          div: '#yoga'
        },
        {
          text: 'Service',
          url: '/',
          div: '#service'
        },
        {
          text: 'Register',
          url: '/',
          div: '#register',
          dontShowSeparator: true
        },
      ]
    }
  }
}
</script>

<style>
header {
  transform: unset !important;
}

.img-line img{
  width: 20%;
}
</style>


