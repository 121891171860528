<template>
  <section class="page-section alt-color">
    <div class="container align-center">
      <h3 class="section-title bottom-line font-alt">Our Treatments<span>The Greatest Pleasure</span>
      </h3>

      <div class="col-md-10 offset-md-1 mb-30">
        <p>Therapeutic Yoga Practices | Meditation Techniques | Breathing Exercises | Teaching various
          Yoga Asanas | Remedies for Life style diseases | Treatments for stress & Anxiety </p>
      </div>
    </div>

    <swiper class="swiper pb-50" :options="swiperOption">
      <swiper-slide>
        <div class="item">
          <div class="portfolio-item ih-item circle effect13 from_left_and_right font-alt">
            <a>
              <div class="portfolio-media">
                <div class="portfolio-img">
                  <img src="../assets/images/portfolio/treat-1.png" alt="Therapeutic Yoga Practices">
                </div>
                <div class="info">
                  <h3>Therapeutic Yoga Practices</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="item">
          <div class="portfolio-item ih-item circle effect13 from_left_and_right font-alt">
            <a>
              <div class="portfolio-media">
                <div class="portfolio-img">
                  <img src="../assets/images/portfolio/treat-2.png" alt="Meditation Techniques">
                </div>
                <div class="info">
                  <h3>Meditation Techniques</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="item">
          <div class="portfolio-item ih-item circle effect13 from_left_and_right font-alt">
            <a>
              <div class="portfolio-media">
                <div class="portfolio-img">
                  <img src="../assets/images/portfolio/treat-3.png" alt="Breathing Exercises">
                </div>
                <div class="info">
                  <h3>Breathing <br> Exercises</h3>
                </div>
              </div>
            </a>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="item">
          <div class="portfolio-item ih-item circle effect13 from_left_and_right font-alt">
            <a>
              <div class="portfolio-media">
                <div class="portfolio-img">
                  <img src="../assets/images/portfolio/treat-4.png" alt="Yoga Asanas">
                </div>
                <div class="info">
                  <h3>Teaching various Yoga Asanas</h3>
                </div>
              </div>
            </a>
          </div>

        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="item">
          <div class="portfolio-item ih-item circle effect13 from_left_and_right font-alt">
            <a>
              <div class="portfolio-media">
                <div class="portfolio-img">
                  <img src="../assets/images/portfolio/treat-5.png" alt="Remedies for Life style diseases">
                </div>
                <div class="info">
                  <h3>Remedies for Life style diseases</h3>
                </div>
              </div>
            </a>
          </div>

        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="item">
          <div class="portfolio-item ih-item circle effect13 from_left_and_right font-alt">
            <a>
              <div class="portfolio-media">
                <div class="portfolio-img">
                  <img src="../assets/images/portfolio/treat-6.png" alt="Treatments for stress & Anxiety">
                </div>
                <div class="info">
                  <h3>Treatments for stress & Anxiety</h3>
                </div>
              </div>
            </a>
          </div>

        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>

    </swiper>

  </section>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'


export default {
  name: "Treatment",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
        breakpoints: {
          1200: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          }
        }
      }
    }
  }
}
</script>
<style>
.pb-50 {
  padding-bottom: 50px !important;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  margin: 5px 7px !important;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #446713 !important;
  line-height: 8px;
  -webkit-box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  -moz-box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.swiper-pagination-bullet-active {
  width: 12px !important;
  height: 12px !important;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #446713;
  line-height: 8px;
  -webkit-box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  -moz-box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

</style>