<template>

  <div class="page" id="top">

    <site-top-panel/>

    <Header v-stickto/>

    <!-- breadcrumb -->

    <section class="breadcrumb-section">
      <div class="container align-center">
        <nav class="breadcrumb">
          <router-link to="/">Home</router-link>
          <a>Therapeutic Yoga</a>
        </nav>
      </div>
    </section>

    <!-- /breadcrumb -->

    <hr class="mt-0 mb-0 alt-2"/>

    <!-- /page section -->

    <section class="small-section">
      <div class="container">


        <h3 class="section-title font-alt">Therapeutic Yoga
          <span>(For Men, Women & Children) </span>
        </h3>
        <div class="row align-justify">
          <div class="col-md-3 mt-10 mt-md-30">
            <div class="exp-media">
              <img src="../../assets/images/portfolio/theraputic-2.png" alt="Therapeutic Yoga-5">
            </div>
          </div>
          <div class="col-md-9 align-justify">
            <p> Yoga comprises a wide range of mind / body practices, ranging from postural and breathing
              exercises to deep relaxation and meditation. Yoga therapy tailors these to the health needs
              of individuals. It promotes all round total health, as well as helping particular medical
              conditions. Yoga offers an excellent training program for the purpose of maintaining one’s
              health. Regular practice of yoga, for minimum 30 to 45 minutes daily, helps not only in
              developing physical fitness but also, in preventing the occurrence of many such ailments
              which invariably result from the hectic pace of modern lifestyle.
            </p>
            <br>
            <p> The fundamental idea behind the practise of yoga is to unite the individual self ( Jiva )
              with the supreme or pure consciousness ( Brahma ). The factual connotation of Yoga is
              unification. Merging in this static realism liberates the spirit from all sense of
              separation. It liberates the mind of the ignorance of time, space and causation. However, as
              Yogic philosophy says, the human body and mind are parts of the deceptive world of matter,
              with a limited period, whereas the spirit is endlessans passes into a new world when this
              body dies. Therefore, fundamental to Yogic philosophy are the concepts of Karma- cause and
              effect relationship.
            </p>
            <router-link to="/#register" class="button small mt-10 mb-20">Register</router-link>
          </div>

          <div class="col-12">
            <p> In the present view, Yoga is one of the most helpful and healthy forms
              of experience to manage the influence of thinking by turning psychological and physical
              force into spiritual energy. Yoga therapy eases oneself from the cramped tension.
              Concentration is enhanced by Yoga. Yoga therapy cures all diseases and controls the aging
              process.</p>
            <p>There are clues to how Yoga improves health in a number of Yoga texts, including the Hatha Yoga
              Pradipika, Patanjali’s Yoga Sutra and the Bhagavad-Gita. For example, the Hatha Yoga Pradipika
              states in chapter 1, Sutra 17, that “postures (asana) give one steadiness (firmness)of body and
              mind, lightness (flexibility) of the limbs, and absence of disease.” The Hatha Yoga Pradipika
              also states that postures such as Matsyendrasana and Paschimottanasana can increase the
              digestive fire to such an incredible capacity that they can remove diseases.
            </p>

            <h3 class="serv-title font-alt">Benefits of Yoga Therapy</h3>

            <p>There are two types of benefits of yoga. One is preventive and another is Curative.
            </p>

            <p> Yoga therapy helps to carry natural regulation and controls to the neuro-hormones and metabolism
              in the body. At the same time yoga therapy improves endocrine metabolism and provides a
              precautionary shelter. The Yogic exercise rinses body, mind and soul by venting toxins from the
              body. The most important benefit of yoga is physical and mental therapy. The aging process,
              which is largely an artificial condition, caused mainly by autointoxication or self-poisoning,
              can be slowed down by practicing yoga. By keeping the body clean, flexible and well lubricated,
              we can significantly reduce the catabolic process of cell deterioration. To get the maximum
              benefits of yoga one has to combine the practices of yogasanas, Pranayama, meditation and
              shatkriyas. Regular practice of asanas, pranayama and meditation can help such diverse ailments
              such as diabetes, blood pressure, digestive disorders, arthritis, arteriosclerosis, chronic
              fatigue, asthma, varicose veins and heart conditions. Laboratory tests have proved the yogi’s
              increased abilities of consciously controlling autonomic or involuntary functions, such as
              temperature, heartbeat, and blood pressure. Research into the effect of yogic practices on HIV
              is currently underway with promising results.
            </p>

            <p>
              According to medical scientists, yoga therapy is successful because of the balance created in
              the nervous and endocrine systems which directly influences all the other systems and organs of
              the body. Yoga acts both as a curative and preventive therapy. The very essence of yoga lies in
              attaining mental peace, improved concentration powers, a relaxed state of living and harmony in
              relationships. Through the practise of yoga, we become aware of the interconnectedness between
              our emotional, mental and physical levels. Gradually this awareness leads to an understanding of
              the more subtle areas of existence. The ultimate goal of yoga is to make it possible for one to
              be able to fuse together the gross materials (annamaya), physical (pranamaya), mental
              (manomaya), intellectual (vijnanamaya) and spiritual (anandamaya) levels within their being.
            </p>


            <h3 class="serv-title font-alt">Physiological Benefits</h3>

            <p>Physicians and scientists are discovering brand new health benefits of yoga everydays. Studies
              show it can relieve the symptoms of several common and potentially life-threatening illnesses
              such as arthritis, arteriosclerosis, chronic fatigue, diabetes, AIDS, asthma and obesity.
              Releases chronic tension throughout the body, Helps relieve stress and stress related conditions
              throughout the body, Helps to alleviate neck and back pain, Helps to balance the immune system.
            </p>
            <p> Regular yoga practice creates mental clarity and calmness, increase body awareness, relieves
              chronic stress patterns, relaxes and quiets the mind, centers attention and sharpens
              concentration.
            </p>


            <h3 class="serv-title font-alt">Self-Awareness</h3>

            <p> Yoga strives to increase Self-awarness on both a physical and psychological level. Patients who
              study yoga learn to induce relaxation and then to use the technical whenever pain appears.
              Practicing yoga can provide chronic pain sufferers with useful tools to actively cope with their
              pain counter feelings of helplessness and depression.
            </p>

            <h3 class="serv-title font-alt">Mental Performance</h3>

            <p> A common technique used in yoda is breathing through one nostril at a time. Electroencephalogram
              (EEG) Studies of the electrical impulses of the brain have shown that breathing through one
              nostril may help improve communication between the right and left side of the brain. Studies
              have also shown that this increased brain activity is associated with better performance and
              doctors even suggest that yoga can enhance cognitive performance.
            </p>

            <h3 class="serv-title font-alt">Mood Change and Vitality</h3>

            <p> Mental health and physical energy are difficult to quantify, but virtually everyone who
              participates in yoga over a period of time reports a positive effect on outlook and energy
              level. Yogic stretching and breathing exercises have been seen to result in an invigorating
              effect on both mental and physical energy and improved mood.
            </p>


            <h3 class="serv-title font-alt">Spiritual Benefits</h3>

            <p> When one achieves the yogic spirit, they can knowing themselves at peace. The value of
              discovering one‟s self as is begins a journey into being rather than doing. Life can then be
              lived practicing “yoga off the mat”. Yoga is one among the six systems of Vedic philosophy.
              Maharishi Patanjali, rightly called “The Father of Yoga” compiled and refined various aspects of
              Yoga systematically in his “Yoga Sutras”(aphorisms). He advocated the eight fold path of Yoga,
              popularly known as “Ashtanga Yoga” for all round development of human personality. They are:-
              Yama, Niyama, Asana, Pranayama, Pratyahara, Dharana, Dhyana and Samadhi. These components
              advocates certain restraints and observances, physical discipline, breath regulations,
              restraining the sense organs, contemplation, meditation and samadhi. These steps are believed to
              have a potential for improvement of physical health by encouraging better circulation of
              oxygenated blood in the body, refraining the sense organs and thereby inducing tranquility and
              serenity of mind. The practice of Yoga prevents psychosomatic disorder / diseases and improves
              an individual‟s resistance and ability to endure stressful situations.
            </p>


            <h3 class="serv-title font-alt">Components of Yoga Therapy</h3>

            <p> Yoga is considered a holistic tool that benefits you three way: body, mind, and spirit.
              Therefore, yoga experts attempt to reach out inside an individual in an effort to gain a better
              understanding of his/her condition and find ways to alter the effects of any malfunctions
              detected inside the body. A good yoga therapist is the key concept here. They will be providing
              the guidance you need in this particular journey and unless you use the correct methods at the
              beginning, you cannot expect success in the end.
            </p>
          </div>


          <div class="col-12">
            <h3 class="serv-title font-alt align-center"> Types of Yoga</h3>

            <div class="row mb-20">
              <div class="col-md-12">
                <ul class="list-style-3 basic-pancha align-justify">
                  <li>
                    <p><b>Japa Yoga</b></p>
                    <p>To concentrate one‟s mind on divine name or holy syllable, mantra etc.
                      like „OM‟, „Rama‟, „Allah‟, „Jesus‟, „God‟, „Guru‟, etc. through
                      repeated recitation on remembrance.
                    </p>
                  </li>
                  <li>
                    <p><b>Karma yoga </b></p>
                    <p> Teaches us to perform all actions without having any desire for their
                      fruit. In this sadhana a Yogi considers his duty as divine action,
                      perform it with whole hearted dedication but shuns away from all
                      desires.
                    </p>
                  </li>
                  <li>
                    <p><b>Jnana Yoga</b></p>
                    <p>Teaches us to discriminate between the self and non-self and to acquire
                      the knowledge of one‟s spiritual entity through the study of scriptures,
                      company of Saints and practice of meditation.
                    </p>
                  </li>
                  <li>
                    <p><b>Bhakti Yoga</b></p>
                    <p> Bhakthi yoga is a system of intense devotion with emphasis on complete
                      surrender to divine will. The true follower of Bhakti Yoga is free from
                      egoism, humble and unaffected by the dualities of the world
                    </p>
                  </li>
                  <li>
                    <p><b>Raja Yoga</b></p>
                    <p> Raja Yoga popularly known as “Ashtanga Yoga” is for all-round
                      development of human personality. These are Yama, Niyama, Asana,
                      Pranayama, Pratyahara, Dharana, Dhyana and Samadhi.
                    </p>
                    <ul class="list-style-3 basic-pancha align-justify">
                      <li>
                        <p><b>Yamas (Observances) </b></p>
                        <p> Practice of Yamas pave way to increase the power of
                          concentration,
                          mental purity and steadiness.
                        </p>
                        <p>The following are the Yama</p>
                        <ul class="list-style-3 basic-pancha align-justify">
                          <li>Ahimsa ( not to harm others)</li>
                          <li> Satya ( to be truthful)</li>
                          <li> Asteya ( not to steal)</li>
                          <li> Brahmacharya (celibacy)</li>
                          <li> Aparigraha ( not to possess beyond actual needs)</li>
                        </ul>
                      </li>
                      <li>
                        <p><b>Niyamas ( Restraints)</b></p>
                        <p>There are five Niyamas</p>
                        <ul class="list-style-3 basic-pancha align-justify">
                          <li> Shauch ( external and internal purification)</li>
                          <li> Santosh (contentment)</li>
                          <li> Tapa ( to make right efforts to achieve goals)</li>
                          <li> Swadhaya ( to study authentic texts and religious
                            scriptures
                            correct knowledge of self and the supreme divinity)
                          </li>
                          <li> Ishwar Pranidhan ( complete surrender to the divine will)
                          </li>
                        </ul>
                        <p> A set of Asanas, Mudras and Pranayamas practiced with faith,
                          perseverance and insight rejuvenates the brain, heart, lungs,
                          liver,
                          pancreas, kidney, bowels, all nerves, muscles,
                          tissues and glands of the body by ensuring oxygenated and
                          balanced blood
                          supply,
                          kindles up the appetite, bestows control over seminal fluid,
                          senses and
                          mind and imparts
                          increased vitality, vigour and longevity to the practitioner.
                        </p>
                      </li>
                      <li>
                        <p><b>Asanas ( Postures)</b></p>
                        <p> These are special patterns of postures that stabilize the mind
                          and the body through
                          static stretching. Their aim is to establish proper system in
                          the neuromuscular tonic
                          impulses and improve the general muscle tone. Two basic
                          principles governing the
                          performance of asanas are stability and comfort. This suggests
                          that the nature of asanas
                          is psycho neuro physical and not only physical. Every asana
                          should be performed
                          effortlessly and maintained for a comfortable time. There should
                          be no jerks and the
                          performance of asana should not lead to undue fatigue.
                        </p>
                        <p>Asanas may be classified as:</p>
                        <ul class="list-style-3 basic-pancha align-justify">
                          <li>Meditative</li>
                          <li>Cultural</li>
                          <li>Relaxative</li>
                        </ul>
                        <ul class="list-style-3 basic-pancha align-justify">
                          <li>
                            <p><b> Meditative Asanas</b></p>
                            <p> Meditative Asanas are sitting postures, which maintain
                              the body in a steady
                              and comfortable condition. By various arrangements of
                              the legs and hands
                              different meditative Asanas are performed. The
                              characteristic feature of the
                              Meditative Asana is, however, keeping the head, neck and
                              trunk erect.

                            </p>
                          </li>
                          <li>
                            <p><b> Cultural Asanas</b></p>
                            <p> Cultural Asanas involve static stretching which brings
                              about proper tone of
                              muscles. They contribute to the flexibility of the spine
                              and render the back and
                              spinal muscles stronger. They also stimulate proper
                              working of the vital organs in
                              the thoracic and abdominal cavities. There are
                              innumerable varieties of cultural
                              Asanas, which are performed in sitting, lying and
                              standing position.
                            </p>
                          </li>
                          <li>
                            <p><b> Relaxative Asanas</b></p>
                            <p> Relaxative Asanas are few in numbers. They are performed
                              in the lying
                              position and are meant for giving rest to the body and
                              mind.

                            </p>
                          </li>

                        </ul>

                      </li>


                      <li>
                        <p><b>Surya Namaskar ( Salutation to sun)</b></p>
                        <p> Surya Namaskar is the most useful and popular mode of Yogic
                          exercises
                          which briefly bestows the benefits of Asanas, Pranayama and
                          Mudras altogether.
                          It consists of a series of 12 postures which are performed early
                          in the morning
                          facing the rising Sun. Surya Namaskar energizes the entire neuro
                          glandular and
                          neuromuscular system of the body and its regular practice
                          ensures a balanced
                          supply of oxygenated blood and perfect harmony to all the
                          systems of the body,
                          thus invigorating the entire psychosomatic system of human
                          constitution.
                        </p>

                      </li>

                      <li>
                        <p><b>Pranayama ( Yogic Respiration)</b></p>
                        <p> These practices bring control over the respiratory impulses,
                          which form one
                          of the channels of the flow of autonomic nerve impulses. Holding
                          of the breath for
                          a prolonged and comfortable time is an essential technique of
                          Pranayama.
                          However, in the initial practice the breath holding phase is
                          completely avoided
                          and emphasis is put one the controlled inspiration and
                          expiration with a time ratio
                          1:2 between them. The expiratory phase is so controlled that the
                          following
                          inspiratory phases is not affected in its slow and controlled
                          inspiration. The main
                          purpose of pranayama is to gain control over the autonomic
                          nervous system and
                          through its influence the mental function is also controlled and
                          regulated. It is
                          useful in higher yogic practices like meditation.
                        </p>

                      </li>

                      <li>
                        <p><b>Pratyahara (Withdrawal of senses from their Objects)</b></p>
                        <p> It is a method for control of the mind. It is a practice for
                          withdrawal of the
                          senses from their respective objects. It is also a process for
                          the prevention of
                          mind wandering and indulgence to unhealthy thoughts, and hence,
                          can be
                          considered as a psychological practice.

                        </p>

                      </li>
                      <li>
                        <p><b>Dharana ( Contemplation)</b></p>
                        <p> It attempts at fixation of mind on a single object or thought or
                          sounds at any
                          place or spot external or internal of the body is Dharana. It
                          improves power of
                          concentration, memory and intellect. Its regular practice
                          results in peace, better
                          mind-body co-ordination and balanced growth.


                        </p>

                      </li>
                      <li>
                        <p><b> Dhyan ( Meditation)</b></p>
                        <p>
                          Continuous and un-interrupted flow of mind in the same direction
                          wherever the mind
                          is fixed is Meditation. This is the practice involving control
                          of the mental function which start from
                          the initial withdrawal of the senses from external objects to
                          the complete oblivion external
                          environment. It is a process of absorption in which the
                          individual tries to turn his attention to
                          experience. It is not always safer to start ones practice in
                          meditation without preparing
                          adequately through Asanas and Pranayama. By regular practice of
                          meditation one acquires the
                          power to concentrate deep resulting in manyfold benefits such as
                          increased physical energy,
                          mental efficiency, creativity, calmness, memory, intellect,
                          psychic power and intuitiveness. The
                          basic principle of meditation is to develop internal awareness.
                        </p>

                      </li>
                      <li>
                        <p><b>Samadhi (Enlighten State)</b></p>
                        <p>
                          Samadhi literally means “completely in agreement”,also called
                          Samapti. It is a state or superconsciousness where trio-the
                          meditator, meditation and meditated upon become one. It is a
                          state of bliss and oneness with immense being ( universal
                          consciousness).Samadhi is the final state of yogic practice. In
                          this phase there is development of mind for appreciation of
                          yogic
                          philosophy, for knowledge which brings salvation- object of
                          sadhana. With the development of
                          Samadhi, there is realization of soul-(Atma), this soul is also
                          Paramatma such as , „AHAM is
                          SOHAM - as the Yogi realizes he is not AHAM (physical existence)
                          only but his existence in
                          every being in every individual as soul. According to Patanjali
                          Darshan – Samadhi can be
                          achieved by the practice of Ishwar pranidhan alone i,e. complete
                          surrender to the divine will.
                        </p>

                      </li>

                    </ul>
                  </li>


                </ul>
              </div>
            </div>
          </div>
        </div>
        <Reference/>
      </div>


    </section>
    <!-- /page section -->

    <!-- footer -->

    <Footer/>

    <!-- / footer -->

    <!-- scroll-top -->

    <Scrolltop/>

    <!-- / scroll-top -->

  </div>

</template>

<script>
import Reference from "@/components/external/reference";
import Header from "@/components/Header";
import SiteTopPanel from "@/components/site-top-panel";
import Footer from "@/components/Footer";
import Scrolltop from "@/components/Scrolltop";


export default {
  name: "theraputic-yoga",
  components: {
    Scrolltop,
    Footer,
    Reference,
    Header,
    SiteTopPanel
  }
}
</script>

<style scoped>

</style>