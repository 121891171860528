import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import OnlineConsultation from '../components/external/Online-consultation'
import YogaForStudents from '../components/external/yoga-for-students'
import TheraputicYoga from '../components/external/theraputic-yoga'
import Panchakarma from '../components/external/Panchakarma'
import Gynaecology from '../components/external/Gynaecology'
import UGCNET from '../components/external/UGC-NET'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/gynaecology/',
        name: 'Gynaecology',
        component: Gynaecology
    },
    {
        path: '/UGCNET/',
        name: 'UGCNET',
        component: UGCNET
    },
    {
        path: '/theraputic-yoga/',
        name: 'TheraputicYoga',
        component: TheraputicYoga
    },
    {
        path: '/yoga-for-students/',
        name: 'YogaForStudents',
        component: YogaForStudents
    },
    {
        path: '/online-consultation/',
        name: 'onlineConsultation',
        component: OnlineConsultation
    },
    {
        path: '/ayurveda-panchakarma-therapy/',
        name: 'Panchakarma',
        component: Panchakarma
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,    routes,

    scrollBehavior: function (to, from, savedPosition) {
        if (from.path !== to.path) {
            if (to.hash) {
                return {selector: to.hash}
            } else {
                return {x: 0, y: 0}
            }
        }
    },

})

export default router
