<template>
  <div class="page" id="top">

    <site-top-panel/>

    <Header v-stickto/>

    <HomeBanner/>

    <!-- page section -->
    <About data-aos="fade-up"/>
    <!-- page section -->

    <section class="page-section alt-color">
      <div class="container">
        <div class="row">

          <!-- banner -->

          <div class="col-md-12 col-lg-6">
            <a>
              <img class="img-border" src="../assets/images/portfolio/profile.png" alt>
            </a>
          </div>
          <div class="col-md-12 col-lg-6 mt-lg-50">
            <h3 class="banner-title font-alt">Dr. Rajitha Ajeesh </h3>
            <div class="title-under font-alt">
              <h3>The Chief Physician</h3>
              <div class="img-line">
                <img src="../assets/images/lines/img-line-26.png" alt="">
              </div>
            </div>
            <div class="section-text mt-10 align-justify">
              <span class="circle-letter-1 font-alt">A</span>
              <p> successful Bachelor of Ayurvedic Medicine & Surgery . She has also achieved qualification as Post
                Graduate Diploma in Yoga (PGDY), UGC NET in Yoga holder and Master of Science in Yoga (M.Sc. Yoga).
                Being an academic expert in the realms of Ayurveda and Yoga, she is also well engaged in providing
                lectures in reputed Schools and Universities, and also serves as the Area Secretary of Ayurveda Medical
                Association of India (AMAI). She is a dedicated follower and teacher of Ayurveda and Yoga practices.
              </p>
            </div>

            <p class="font-alt narrat mb-0">Learn to self heal at Naaham
            <p style="float:right;">- Dr. Rajitha Ajeesh</p>

          </div>

          <!-- /banner -->

        </div>
      </div>
    </section>

    <!-- /page section -->

    <!-- /page section -->

    <section class="small-section" id="ayurveda">
      <div class="container">


        <h3 class="section-title font-alt">Ayurveda
          <span>The science of lifespan</span>
        </h3>
        <hr class="mt-40 mb-60 alt-2"/>
        <div class="row">
          <div class="col-md-9 align-justify">
            <p>Ayurveda is a Sanskrit word meaning ‘The science of lifespan’. It is one of the world’s most
              ancient and effective healing systems. India is the birth place of Ayurveda. The state of
              Kerala,
              known as the God’s own country, is famous for its rich ayurvedic traditions and has
              contributed
              immensely to the field of Ayurveda. Many eminent Ayurveda physicians (Ashtavaidyas) had
              their
              origins in Kerala. It is very inspiring to note that majority of people around the world are
              now
              leaning towards Ayurveda, the 5000 years old practice of Kerala, that has proved its mettle
              in
              curing various illnesses of mankind in a variety of complicated cases.</p>
            <p>Apart from its curing effects,
              Ayurveda is now also hailed for its incredible power to restore immunity and instil energy
              into
              human beings, both physically and psychologically, through its own natural medicinal
              properties
              and unique application of practices. As dedicated devotees of Ayurveda, we humbly bow our
              heads
              to our ancestors whose knowledge and experience that transformed this branch of knowledge
              into a
              world famous practice.</p>
          </div>
          <div class="col-md-3 mt-10 mt-md-30">
            <div class="exp-media">
              <img src="../assets/images/portfolio/Ayurveda-1.png" alt="Ayurveda-1">
            </div>
          </div>
        </div>

        <div class="container align-center">

          <!-- news filter items -->

          <ul>
            <li class="row services-item all counselling face mb-50 mt-50">
              <div class="col-12 col-sm-12 col-md-1"></div>
              <div class="col-8 col-md-4 mb-30">
                <div class="news-item alt-2">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/Ayurveda-2.png" alt="Ayurveda-2">
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-2 mb-30">
                <div class="news-item alt-2 mini mb-30">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/Ayurveda-4.png" alt="Ayurveda-4">
                  </div>
                </div>
                <div class="news-item alt-2 mini">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/Ayurveda-3.png" alt="Ayurveda-3">
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-12 align-left">
                <h3 class="serv-title font-alt">Benefits of Ayurveda</h3>

                <div class="row mb-20">
                  <div class="col-md-12">
                    <ul class="list-style-3">
                      <li>
                        Increases the immunity power
                      </li>
                      <li>
                        Helps Weight Loss & Health Maintenance
                      </li>
                      <li>
                        Smooth and Glowing Skin & Hair
                      </li>
                      <li>
                        Reduces the mental stress
                      </li>
                      <li>
                        Reduce bodily Inflammations
                      </li>
                      <li>
                        Traditional ways to cleanse the body
                      </li>
                      <li>
                        Lower Blood Pressure (Hypertension)
                      </li>
                      <li>
                        Maintaining Cholesterol level
                      </li>
                      <li>
                        Natural remedies for Diabetes
                      </li>
                      <li>
                        Effective solutions for reducing obesity
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>

        </div>
      </div>


    </section>

    <!-- /page section -->


    <!-- /page section -->

    <section class="small-section alt-color" id="yoga">
      <div class="container">


        <h3 class="section-title font-alt">Yoga
          <span>Routines for better life </span>
        </h3>
        <div class="sep-lane mt-50 mb-50"><span>
                    <img src="../assets/images/lines/img-line-0.png"
                         data-at2x="../assets/images/lines/img-line-0@2x.png"
                         alt></span>
        </div>
        <div class="row">
          <div class="col-md-3 mt-10 mb-md-30">
            <div class="exp-media">
              <img src="../assets/images/portfolio/yoga-1.png" alt="yoga-1">
            </div>
          </div>
          <div class="col-md-9 align-justify">
            <p>Yoga is a traditional way of doing daily exercises to maintain the physical as well as
              psychological health originated in ancient India and comprises of physical, mental, and
              spiritual
              practices. The rituals of Yoga are mentioned in the Vedas and Upanishads (most ancient
              sacred writings of Hinduism written in Sanskrit. In Indian traditions, Yoga is more than
              physical
              exercise; it has a meditative and spiritual core. The word Yoga means Union. </p>
            <p> The spiritual
              sense of
              the word yoga is associated with the philosophical system presented in the Yoga Sutras with
              an
              objective of "uniting" the human spirit with the Divine spirit. The practice of Yoga has
              developed
              into a posture-based physical fitness, stress-relief and relaxation technique.</p>
          </div>
        </div>

        <div class="container align-center">
          <!-- news filter items -->

          <ul>
            <li class="row services-item all counselling face mb-50 mt-50">
              <div class="col-md-5 col-12 align-left">
                <h3 class="serv-title font-alt">Benefits of Yoga</h3>

                <div class="row mb-20">
                  <div class="col-md-12">
                    <ul class="list-style-3">
                      <li>
                        Improves your flexibility of body
                      </li>
                      <li>
                        Builds muscle strength
                      </li>
                      <li>
                        Prevents arthritis, Back or Joint pains.
                      </li>
                      <li>
                        Boosts your immune system functionality
                      </li>
                      <li>
                        Reduces the mental stress
                      </li>
                      <li>
                        Improves the blood circulation.
                      </li>
                      <li>
                        Helps relaxation of mind.
                      </li>
                      <li>
                        Increases the ability to concentrate
                      </li>
                      <li>
                        Boosts memorization capacity
                      </li>
                      <li>
                        Revives the presence of mind.
                      </li>
                      <li>
                        Helps control emotional outbursts
                      </li>
                      <li>
                        Reduces the effects of obesity.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-2 mb-30">
                <div class="news-item alt-2 mini mb-30">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/yoga-3.png" alt="yoga-3">
                  </div>
                </div>
                <div class="news-item alt-2 mini">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/yoga-4.png" alt="yoga-4">
                  </div>
                </div>
              </div>

              <div class="col-8 col-md-4 mb-30">
                <div class="news-item alt-2">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/yoga-2.png" alt="yoga-2">
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-1"></div>


            </li>
          </ul>

        </div>
        <div class="sep-lane mt-50 mb-50"><span>
                    <img src="../assets/images/lines/img-line-0.png"
                         data-at2x="../assets/images/lines/img-line-0@2x.png"
                         alt></span>
        </div>
      </div>


    </section>

    <!-- /page section -->

    <!-- page section -->

    <Service/>

    <!-- /page section -->

    <div class="sep-lane alt-3"><span></span></div>

    <!-- page section -->

    <Treatment/>

    <!-- /page section -->

    <!-- /page section -->

    <section class="small-section">
      <div class="container">


        <h3 class="section-title font-alt">Therapeutic Yoga
          <span>(For Men, Women & Children) </span>
        </h3>
        <hr class="mt-40 mb-60 alt-2"/>
        <div class="row">
          <div class="col-md-9 align-justify">
            <p>Therapeutic Yoga is a scientific application of yoga practices for the purpose of healing
              many a human diseases and bringing physical and psychological conditions back to its normal
              stages. According medical feedbacks, Therapeutic Yoga has been proven to be the most
              effective is
              mode of physiotherapy treatment for various illnesses like Arthritis, Musculoskeletal pain,
              Back
              pain, Hip pain, Shoulder injury, Athletic injuries, Heart diseases, Migraines, Psychological
              disorders,
              Osteoporosis, Sinusitis etc. </p>
            <br>
            <p>Therapeutic Yoga ensures both preventive and restorative
              aspects of
              medical treatments. It requires a deep assessment of the human body considering the nature
              and
              features of various individuals.</p>
          </div>
          <div class="col-md-3 mt-10 mt-md-30">
            <div class="exp-media">
              <img src="../assets/images/portfolio/theraputic-1.png" alt="Therapeutic Yoga-1">
            </div>
          </div>
        </div>

        <div class="container align-center">

          <!-- news filter items -->

          <ul>
            <li class="row services-item all counselling face mb-50 mt-50">
              <div class="col-8 col-md-4 mb-30">
                <div class="news-item alt-2">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/theraputic-2.png" alt="Therapeutic Yoga-2">
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-2 mb-30">
                <div class="news-item alt-2 mini mb-30">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/theraputic-3.png" alt="Therapeutic Yoga-3">
                  </div>
                </div>
                <div class="news-item alt-2 mini">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/theraputic-4.png" alt="Therapeutic Yoga-4">
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12 align-justify">
                <p>Here is the list of common ailments that are benefited by specific therapeutic
                  programs. This list is not exhaustive, but merely indicative
                </p>
                <div class="row mb-20">
                  <div class="col-6">
                    <ul class="list-style-3">
                      <li>
                        Anxiety
                      </li>
                      <li>
                        Arthritis
                      </li>
                      <li>
                        Asthma
                      </li>
                      <li>
                        Depression
                      </li>
                      <li>
                        Musculoskeletal Pain
                      </li>
                      <li>
                        Back Pain
                      </li>
                      <li>
                        Hip Pain
                      </li>
                      <li>
                        Shoulder injury
                      </li>
                      <li>
                        Athletic injuries
                      </li>
                      <li>
                        Heart diseases
                      </li>
                      <li>
                        Migraines
                      </li>
                      <li>
                        Carpal Tunnel Syndrome
                      </li>
                      <li>
                        Sciatica
                      </li>
                    </ul>
                  </div>
                  <div class="col-6">
                    <ul class="list-style-3">

                      <li>
                        Psychological disorders
                      </li>
                      <li>
                        Post Cancer Effects
                      </li>
                      <li>
                        Somatic dysfunctions
                      </li>
                      <li>
                        Aids / HIV+
                      </li>
                      <li>
                        Insomnia
                      </li>
                      <li>
                        Menstrual disorders
                      </li>
                      <li>
                        Infertility
                      </li>
                      <li>
                        Osteoporosis
                      </li>
                      <li>
                        Sinusitis
                      </li>
                      <li>
                        Insomnia
                      </li>
                      <li>
                        Post Traumatic Syndrome
                      </li>
                      <li>
                        Stress
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <!--            <a href="theraputic-yoga.html" class="button small mt-20 float-right-md">Learn more</a>-->

        </div>


      </div>


    </section>

    <!-- /page section -->

    <!-- /page section -->

    <section class="small-section alt-color">
      <div class="container">


        <h3 class="section-title font-alt">Yoga for Students
          <span>(For Boys & Girls) </span>
        </h3>
        <div class="sep-lane mt-50 mb-50"><span>
                    <img src="../assets/images/lines/img-line-0.png"
                         data-at2x="../assets/images/lines/img-line-0@2x.png"
                         alt></span>
        </div>
        <div class="row">
          <div class="col-md-3 mt-10 mb-md-30">
            <div class="exp-media">
              <img src="../assets/images/portfolio/student-2.png" alt="Yoga for Students-2">
            </div>
          </div>
          <div class="col-md-9 align-justify">
            <p>Yoga is a form of routine exercises that helps the physical, mental and spiritual well-being
              of
              the individual. In India, National Curriculum Framework has categorized Yoga as an integral
              component of health and physical education, which should be a mandatory subject till the
              secondary school. Through the process of controlled breathing and improved focus, Yoga
              teaches to
              calm oneself in distressing times. </p>
            <br>
            <p> Yoga can create a positive impact on the social and mental
              development of a child. Schools being incredibly stressful, Yoga teaches them to handle
              stress
              through the proper channelling of energy guides them to a stress-free life. Yoga also helps
              students
              to deal with their stress and keep their minds at peace. It increases the memory power and
              confidence level and subsequently improves their academic performances.</p>
          </div>
        </div>

        <div class="container align-center">

          <!-- news filter items -->

          <ul>
            <li class="row services-item all counselling face mb-50 mt-50">
              <div class="col-md-5 col-12 align-left">
                <h3 class="serv-title font-alt">Benefits of Yoga for Students</h3>

                <div class="row mb-20">
                  <div class="col-md-12">
                    <ul class="list-style-3">
                      <li>
                        Increases the immunity power
                      </li>
                      <li>
                        Helps Weight Loss & Health Maintenance
                      </li>
                      <li>
                        Smooth and Glowing Skin & Hair
                      </li>
                      <li>
                        Reduces the mental stress
                      </li>
                      <li>
                        Reduce bodily Inflammations
                      </li>
                      <li>
                        Traditional ways to cleanse the body
                      </li>
                      <li>
                        Lower Blood Pressure (Hypertension)
                      </li>
                      <li>
                        Maintaining Cholesterol level
                      </li>
                      <li>
                        Natural remedies for Diabetes
                      </li>
                      <li>
                        Effective solutions for reducing obesity
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-2 mb-30">
                <div class="news-item alt-2 mini mb-30">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/student-5.png" alt="Yoga for Students-5">
                  </div>
                </div>
                <div class="news-item alt-2 mini">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/student-3.png" alt="Yoga for Students-3">
                  </div>
                </div>
              </div>

              <div class="col-8 col-md-4 mb-30">
                <div class="news-item alt-2">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/student-4.png" alt="Yoga for Students-4">
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-1"></div>


            </li>
          </ul>


          <!--          <a href="yoga-for-students.html" class="button small mt-20 float-left-md">Learn more</a>-->

          <div class="sep-lane mt-50 mb-50"><span>
                    <img src="../assets/images/lines/img-line-0.png"
                         data-at2x="../assets/images/lines/img-line-0@2x.png"
                         alt></span>
          </div>
        </div>
      </div>

    </section>

    <!-- /page section -->

    <section class="small-section">
      <div class="container">


        <h3 class="section-title font-alt">Online Consultation Facilities
          <!--          <span>(For Men, Women & Children) </span>-->
        </h3>
        <hr class="mt-40 mb-60 alt-2"/>
        <div class="row">
          <div class="col-md-9 align-justify">
            <p>Naaham is an authentic clinic for Ayurveda & Yoga treatments and practices. At Naaham we
              promise personalized care and attention to every individual who are interested in following
              the
              natural ways of healing. The treatment modalities include herbal medicines, diet and
              lifestyle
              management to ensure the desire results. Consult our doctors to assess the unique
              characteristics
              of your body as per Ayurvedic principles and various Ayurvedic treatments that are suitable
              to
              your
              body.</p>
            <br>
            <p> We provide clinical as well as online consultation facilities at the convenience of our
              customers. Please feel free to contact our help desk for more information on various types
              of
              consultations provided by us. You can also book an appointment with our doctors at a
              convenient
              date and time for consultation. </p>
            <!--            <a href="online-consultation.html" class="button small mt-20">Learn more</a>-->

          </div>
          <div class="col-md-3 mt-10 mt-md-30">
            <div class="exp-media">
              <img src="../assets/images/portfolio/online.png" alt="Online Consultation Facilities">
            </div>
          </div>
        </div>


      </div>


    </section>


    <!-- /page section -->

    <section class="small-section alt-color">
      <div class="container">


        <h3 class="section-title font-alt">Ayurveda Panchakarma Therapy
          <span>(For Men & Women) </span>
        </h3>
        <div class="sep-lane mt-50 mb-50"><span>
                    <img src="../assets/images/lines/img-line-0.png"
                         data-at2x="../assets/images/lines/img-line-0@2x.png"
                         alt=""></span>
        </div>
        <div class="row">
          <div class="col-md-9 align-justify">
            <p> Ayurveda, an ancient indian system of Medicine, has been in vogue from times immemorial to
              impart natural healing for various ailments. The system was nurtured by ancient scholars on
              the basis of sound underlying philosophy, oriental methodology and practices prevalent in
              that era. This system is believed to be quite safe and free from side effects as it is more
              close to nature.
            </p>

            <p> Ayurvedic treatments comprises of two major parts Viz. Samshodhana chikitsa
              ( Bio-cleaning therapy ) and Samshaman Chikitsa ( pacifying therapy ). Samshaman chikitsa
              consists of conservative treatment like Langhana,pachana, drug administration etc.
              Samshodhana chikitsa envisages Panchakarma treatment which is basically a Bio-cleaning
              regimen intended to eliminate the toxic elements from the body and thereby enhances the
              immunity of the body. Samshodhana chikitsa is considered superior to Samshaman Chikitsa as
              the chances for recurrence of the disease so treated are remote.</p>
          </div>
          <div class="col-md-3 mt-10 mt-md-30">
            <div class="exp-media">
              <img src="../assets/images/portfolio/pancha-1.png" alt="Ayurveda Panchakarma Therapy-1">
            </div>
          </div>
        </div>


        <div class="container align-center">

          <!-- news filter items -->

          <ul id="">
            <li class="row services-item all counselling face mb-50 mt-50">
              <div class="col-12 col-sm-12 col-md-1"></div>
              <div class="col-8 col-md-4 mb-30">
                <div class="news-item alt-2">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/pancha-2.png" alt="Ayurveda Panchakarma Therapy-2">
                  </div>
                </div>
              </div>
              <div class="col-4 col-md-2 mb-30">
                <div class="news-item alt-2 mini mb-30">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/pancha-3.png" alt="Ayurveda Panchakarma Therapy-3">
                  </div>
                </div>
                <div class="news-item alt-2 mini">
                  <div class="news-media">
                    <img src="../assets/images/portfolio/pancha-4.png" alt="Ayurveda Panchakarma Therapy-4">
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-12 align-left">
                <!--                  <h3 class="serv-title font-alt">Benefits of Ayurveda</h3>-->
                <p class="align-justify">Pancakarma increases the acceptability of the body to
                  various therapeutic
                  regimens like Rasayana (Rejuvenation) and Vajikarana (Aphrodisiac). Thus,
                  Panchakarma therapy is believed to impart radical elimination of disease causing
                  factors and maintain the equilibrium of Doshas. Five fold measures comprehended
                  as Pancakarma are:
                </p>
                <div class="row mb-20">
                  <div class="col-md-12">


                    <ul class="list-style-3">
                      <li>
                        Vamana (Therapeutic Emesis)
                      </li>
                      <li>
                        Virechana (Therapeutic Purgation)

                      </li>
                      <li>
                        Anuvasana (Medicated Oil Enema)
                      </li>
                      <li>
                        Asthapana (Medicated Decoction Enema)
                      </li>
                      <li>
                        Nasya (Nasal administration of Medicaments)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <!--            <a href="panchakarma.html" class="button small mt-20 float-right-md">Learn more</a>-->

        </div>
      </div>


    </section>

    <!-- /page section -->


    <!-- page section -->
    <Session/>

    <Register/>


    <Scrolltop/>

    <Footer/>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import About from "../components/About";
import SiteTopPanel from "@/components/site-top-panel.vue";
import Scrolltop from "@/components/Scrolltop";
import Register from "@/components/Register";
import HomeBanner from "@/components/Home-banner";
import Service from "@/components/Service";
import Treatment from "@/components/Treatment";
import Session from "@/components/Session";


export default {
  name: "index",
  components: {
    Session,
    Treatment,
    Service,
    HomeBanner,
    Register,
    Scrolltop,
    Header,
    Footer,
    About,
    SiteTopPanel,
  }
}
</script>


<style scoped>

</style>