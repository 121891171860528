<template>

  <div class="page" id="top">

    <site-top-panel/>

    <Header v-stickto/>
    <!-- breadcrumb -->

    <section class="breadcrumb-section">
      <div class="container align-center">
        <nav class="breadcrumb">
          <router-link to="/">Home</router-link>
          <a>Ayurveda Panchakarma Therapy</a>
        </nav>
      </div>
    </section>

    <!-- /breadcrumb -->

    <hr class="mt-0 mb-0 alt-2"/>

    <!-- /page section -->

    <section class="small-section">
      <div class="container align-justify">


        <h3 class="section-title font-alt">Ayurveda Panchakarma Therapy
          <span>(For Men & Women) </span>
        </h3>
        <div class="row align-justify">
          <div class="col-lg-3 mt-10 mt-md-30">
            <div class="exp-media">
              <img src="../../assets/images/portfolio/pancha-1.png" alt="Ayurveda Panchakarma Therapy-5">
            </div>
          </div>
          <div class="col-lg-9 align-justify">
            <p> Ayurveda emphasizes preventative and healing therapies along with various methods of
              purification and rejuvenation. Ayurveda is more than a mere healing system; it is a science
              and an art of appropriate living that helps to achieve longevity. It can guide every
              individual in the proper choice of diet, living habits and exercise to restore balance in
              the body, mind and consciousness, thus preventing disease from gaining a foothold in the
              system.</p>

            <p> According to Ayurveda, every human being is a unique phenomenon of cosmic consciousness,
              manifested through the five basic elements—Ether, Air, Fire, Water and Earth. Vata—a
              combination of ether and air, pitta—a combination of fire and water, and kapha—a combination
              of water and earth, are called the tridosha. These are the three humors or the three
              organizations of the body, which are also derived from consciousness. Each individual
              constitution or psycho-somatic temperament is determined by the relative proportions of
              these three doshas at the time of fertilization. </p>
              <router-link to="/#register" class="button small mt-10 mb-20">Register</router-link>
          </div>

        </div>
        <div class="clearfix"></div>
        <p>When the embryo is formed, the constitution
          is determined. There are seven basic constitutions with one or more doshas predominant
          according to Ayurveda. They are: vata, pitta or kapha predominant, vata-pitta, pitta- kapha
          or kapha-vata predominant and vata-pitta-kapha in equal balance, a rare occurrence.</p>
        <p>Every individual constitution has its own unique balance of vata, pitta and kapha (VPK) according to
          its own nature. This balance of VPK is the natural order. When this doshic balance is disturbed, it
          creates imbalance, which is disorder. Health is order; disease is disorder. Within the body there is
          a constant interaction between order and disorder, thus once one understands the nature and
          structure of disorder, one can re-establish order. Ayurveda believes that order lies within
          disorder.</p>
        <p> Order is the state of health, as defined by Ayurveda. This exists when the digestive fire (agni) is
          in a balanced condition; the bodily humors (vata, pitta and kapha) are in equilibrium, the three
          waste products (urine, feces and sweat) are produced and eliminated normally, the seven bodily
          tissues (rasa, rakta, mamsa, meda, asthi, majja and shukra/artava) are functioning normally, and the
          mind, senses and consciousness are working harmoniously together. When the balance of these systems
          is disturbed, the disease (disorder) process begins.
        </p>
        <p> The internal environment is governed by vata, pitta and kapha, which are constantly reacting to the
          external environment. The wrong diet, habits, lifestyle, incompatible food combinations (e.g., milk
          and fish, melons and grain, yogurt and meat or eating cooked honey, etc.), seasonal changes,
          repressed emotions and stress factors can all act either together or separately to change the
          balance of vata, pitta and kapha. According to the nature of the cause, vata, pitta or kapha undergo
          aggravation or derangement, which affects the agni (gastric fire) and produces ama (toxins).
        </p>
        <p>This ama enters the blood stream and is circulated throughout the body, clogging the channels.
          Retention of toxins in the blood results in toxemia. This accumulated toxicity, once well
          established, will slowly affect prana (vital life energy), ojas (immunity), and tejas (cellular
          metabolic energy), resulting in disease. This can be nature’s effort to eliminate toxicity from the
          body. Every so-called disease is a crisis of ama toxicity. Ama is the basic internal cause of all
          disease, due to the aggravated doshas.</p>
        <p>Herein lies the key to the prevention of disease: help the body eliminate the toxins. To stop the
          further production of ama, Ayurvedic literature suggests putting the person on a proper diet with
          appropriate lifestyle, habits and exercise, and administering a proper cleansing program such as
          panchakarma. (Although panchakarma is often thought of as the entire procedure, it really is only
          one part of a group of therapies belonging to a class of cleansing procedures called shodana. There
          is also a group of milder techniques called shamana for those not strong enough for shodana.)</p>

        <h3 class="serv-title font-alt">Purvakarma: Pre-purification Measures</h3>

        <p> Before the actual operation of purification begins, there is a need to prepare the body with
          prescribed methods to encourage it to let go of the toxins. These two procedures are snehan and
          svedana. Snehan is the oil massage. Oil is applied to the entire body with a particular type of
          massage that helps the toxins to move towards the gastrointestinal tract. Oil massage also makes the
          superficial and deep tissues soft and supple, thus helping to remove stress and nourish the nervous
          system. Snehan is given daily for three to seven days, as indicated. Svedana is sudation or sweating
          and is given every day immediately following the snehan. An herbal concoction may be added to the
          steam to further loosen the toxins from the individual. Svedana liquefies the toxins and increases
          the movement of toxins into the gastrointestinal tract. After three to seven days of snehan and
          svedana, the doshas become well “ripened.” A particular panchakarma method is then given according
          to the individual’s constitution and disorder, prakruti and vikruti, respectively.</p>

        <h3 class="serv-title font-alt align-center">Five Basic Shodanas: Cleansing Methods</h3>
        <ul class="list-style-3 basic-pancha align-left">
          <li>Vamana: therapeutic vomiting or emesis</li>
          <li>Virechan: purgation</li>
          <li>Basti: enema</li>
          <li>Nasya: elimination of toxins through the nose</li>
          <li>Rakta Moksha: detoxification of the blood</li>
        </ul>

        <ul class="list-style-3 basic-pancha align-justify">
          <li>
            <p><b>Vamana: Emesis Therapy</b></p>
            <p>When there is congestion in the lungs causing repeated attacks of bronchitis, colds, cough or
              asthma, the Ayurvedic treatment is therapeutic vomiting, vamana, to eliminate the kapha
              causing the excess mucus. Often times this also releases repressed emotions that have been
              held in the kapha areas of the lungs and stomach along with the accumulated dosha. Once the
              mucus is released, the patient will feel instantly relieved. It is likely that congestion,
              wheezing and breathlessness will disappear and that the sinuses will become clear.
              Therapeutic vomiting is also indicated in chronic asthma, diabetes, chronic cold, lymphatic
              congestion, chronic indigestion and edema.
            </p>
            <p>After vamana, resting, fasting, smoking certain herbal cigarettes, and not suppressing
              natural urges (i.e., urination, defecation, gas, sneezing, coughing) is recommended. If
              vamana is administered properly, the person should feel relaxation in the lungs, will be
              able to breathe freely, will have lightness in the chest, clear thinking, a clear voice, a
              good appetite, and all symptoms of congestion disappear.
            </p>
          </li>
          <li>
            <p><b>Virechan: Purgation Therapy</b></p>
            <p> When excess bile, pitta, is secreted and accumulated in the gall bladder, liver and small
              intestine, it tends to result in rashes, skin inflammation, acne, chronic attacks of fever,
              biliary vomiting, nausea and jaundice. Ayurvedic literature suggests in these conditions the
              administration of therapeutic purgation or a therapeutic laxative. Purgatives help relieve
              the excess pitta causing the bile disturbance in the body. In fact, purgatives can
              completely cure the problem of excess pitta. When purgatives are used, the patient should
              not eat foods that will aggravate the predominant humor or cause the three humors to become
              unbalanced.
            </p>

          </li>
          <li>
            <p><b>Basti: Enema Therapy</b></p>
            <p>Vata is a very active principle in pathogenesis (disease). If we can control vata through the
              use of basti, we have gone a long way in going to the root cause of the vast majority of
              diseases. Vata is the main etiological (causal) factor in the manifestation of diseases. It
              is the motive force behind the elimination and retention of feces, urine, bile and other
              excreta. Vata is mainly located in the large intestine, but bone tissue (asthi dhatu) is
              also a site for vata. Hence the medication administered rectally effects asthi dhatu. The
              mucus membrane of the colon is related to the outer covering of the bones (periosteum),
              which nourishes the bones. Therefore, any medication given rectally goes into the deeper
              tissues, like bones, and corrects vata disorders.
            </p>
          </li>
          <li>
            <p><b>Nasya: Nasal Administration</b></p>
            <p>The nose is the doorway to the brain and it is also the doorway to consciousness. The nasal
              administration of medication is called nasya. An excess of bodily humors accumulated in the
              sinus, throat, nose or head areas is eliminated by means of the nearest possible opening,
              the nose.
            </p>
            <p>Prana, life force as nerve energy, enters the body through the breath taken in through the
              nose. Prana is in the brain and maintains sensory and motor functions. Prana also governs
              mental activities, memory, concentration and intellectual activities. Deranged prana creates
              defective functioning of all these activities and produces headaches, convulsions, loss of
              memory and reduced sensory perception. Thus nasal administration, nasya, is indicated for
              prana disorders, sinus congestion, migraine headaches, convulsions and certain eye and ear
              problems.
            </p>
            <p> Breathing also can be improved through nasal massage. For this treatment, the little finger
              is dipped into ghee and inserted into the nose. The inner walls of the nose are slowly
              massaged, going as deeply as possible. This treatment will help to open the emotions. (Nose
              tissue is tender and for this application the fingernail must be kept short to avoid
              injuring the delicate mucus membranes.) Since most people have a deviated nasal septum, one
              side of the nose will be easier to penetrate and massage than the other. The finger should
              not be inserted forcibly. The massage should proceed by slow penetration, the finger moving
              first in a clockwise and then counter-clockwise direction. By this means, the emotions that
              are blocked in the respiratory tract will be released. One may use this treatment each
              morning and evening. In this way, breathing patterns will change as the emotions are
              released and the eyesight also will improve.
            </p>
          </li>
        </ul>

        <Reference/>

      </div>


    </section>
    <!-- /page section -->

    <!-- footer -->

    <Footer/>

    <!-- / footer -->

    <!-- scroll-top -->

    <Scrolltop/>

    <!-- / scroll-top -->

  </div>

</template>

<script>
import Reference from "@/components/external/reference";
import Header from "@/components/Header";
import SiteTopPanel from "@/components/site-top-panel";
import Footer from "@/components/Footer";
import Scrolltop from "@/components/Scrolltop";


export default {
  name: "Panchakarma",
  components: {
    Scrolltop,
    Footer,
    Reference,
    Header,
    SiteTopPanel
  }
}
</script>

<style scoped>

</style>
