<template>
  <section class="page-section " id="service">
    <div class="container align-center">
      <h3 class="section-title bottom-line font-alt">Our Services</h3>
      <div class="col-md-8 offset-md-2 mb-30">
        <p>Therapeutic Yoga | Yoga for Students | Online Consultation Facilities | Ayurveda Panchakarma Therapy |
          Gynaecology | UGC NET JRF
        </p>
      </div>

      <div class="clearfix"></div>

      <!--      <carousel :autoplay="true" :loop="true" :responsive="{0:{items:1},552:{items:2},992:{items:3},1200:{items:4}}"-->
      <!--                :dots="true" :nav="false">-->
      <swiper class="swiper home-slider pb-50" :options="swiperOption">
        <swiper-slide>
          <div class="item">
            <div class="shop-container align-center alt-2">
              <a>
                <div class="shop-media">
                  <div class="shop-img">
                    <img src="../assets/images/portfolio/pancha-5.jpg" alt="gynaecology1">
                  </div>
<!--                  <div class="label-new label-left font-alt">New</div>-->
                </div>
              </a>
              <h3 class="shop-title font-alt">Gynaecology</h3>
              <router-link to="/gynaecology/" class="button small mt-20">Learn more</router-link>
            </div>

          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div class="shop-container align-center alt-2">
              <a>
                <div class="shop-media">
                  <div class="shop-img">
                    <img src="../assets/images/portfolio/pancha-6.jpg" alt="UGCNET1">
                  </div>
<!--                  <div class="label-new label-left font-alt">New</div>-->
                </div>
              </a>
              <h3 class="shop-title font-alt">UGC NET JRF</h3>
              <router-link to="/UGCNET/" class="button small mt-20">Learn more</router-link>
            </div>

          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div class="shop-container align-center alt-2">
              <a>
                <div class="shop-media">
                  <div class="shop-img">
                    <img src="../assets/images/portfolio/theraputic-1.png" alt="theraputic-yoga1">
                  </div>
                </div>
              </a>
              <h3 class="shop-title font-alt">Therapeutic Yoga</h3>
              <router-link to="/theraputic-yoga/" class="button small mt-20">Learn more</router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div class="shop-container align-center alt-2">
              <a>
                <div class="shop-media">
                  <div class="shop-img">
                    <img src="../assets/images/portfolio/student-1.png" alt="yoga-for-students1">
                  </div>
                </div>
              </a>
              <h3 class="shop-title font-alt">Yoga for Students</h3>
              <router-link to="/yoga-for-students/" class="button small mt-20">Learn more</router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div class="shop-container align-center alt-2">
              <a>
                <div class="shop-media">
                  <div class="shop-img">
                    <img src="../assets/images/portfolio/online.png" alt="online-consultation1">
                  </div>
                </div>
              </a>
              <h3 class="shop-title font-alt">Online Consultation Facilities</h3>
              <!--              <a href="online-consultation.html" class="button small mt-20">Learn more</a>-->
              <router-link to="/online-consultation/" class="button small mt-20">Learn more</router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <div class="shop-container align-center alt-2">
              <a>
                <div class="shop-media">
                  <div class="shop-img">
                    <img src="../assets/images/portfolio/pancha-2.png" alt="ayurveda-panchakarma-therapy1">
                  </div>
                </div>
              </a>
              <h3 class="shop-title font-alt">Ayurveda Panchakarma</h3>
              <router-link to="/ayurveda-panchakarma-therapy/" class="button small mt-20">Learn more</router-link>
            </div>

          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>

      </swiper>
      <!--      </carousel>-->

    </div>
  </section>
</template>

<script>

import {Swiper, SwiperSlide} from 'vue-awesome-swiper'


export default {
  name: "Service",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 10,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
        breakpoints: {
          1200: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          }
        }
      }
    }
  }
}
</script>

<style>

.pb-50 {
  padding-bottom: 50px !important;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  margin: 5px 7px !important;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #446713 !important;
  line-height: 8px;
  -webkit-box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  -moz-box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.swiper-pagination-bullet-active {
  width: 12px !important;
  height: 12px !important;
  filter: Alpha(Opacity=50);
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #446713;
  line-height: 8px;
  -webkit-box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  -moz-box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  box-shadow: 0 0 0 3px #fff inset, 0 0 0 2px #c7d5d3;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
</style>