<template>
  <div>
    <h3 class="serv-title font-alt align-center">References</h3>
    <div class="row">
      <div class="col-md-6">
        <ul class="list-style-3 basic-pancha align-left">
          <li>A Practical Handbook of Panchakarma Procedures
            Prof. G. S. Lavekar. ( Reprint 2020 )
          </li>
          <li>
            Screen Shot
          </li>
          <li>Yoga - A Healing way of Living Secondary Stage.NCERT.
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <ul class="list-style-3 basic-pancha align-left">
          <li>Yoga for Health - N. S. Ravishankar
          </li>
          <li>Ayurveda - A Brief Introduction and Guide
            By - Vasant Lad, B.A.M.S, M.A.Sc
          </li>
          <li>An Introduction to Panchakarma
            By - Vasant Lad, B.A.M.S, M.A.Sc
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reference"
}
</script>

<style scoped>

</style>