<template>

  <div class="page" id="top">

    <site-top-panel/>

    <Header v-stickto/>
    <!-- breadcrumb -->

    <section class="breadcrumb-section">
      <div class="container align-center">
        <nav class="breadcrumb">
          <router-link to="/">Home</router-link>
          <a>Online Consultation Facilities</a>
        </nav>
      </div>
    </section>

    <!-- /breadcrumb -->

    <hr class="mt-0 mb-0 alt-2"/>

    <!-- /page section -->

    <section class="small-section">
      <div class="container align-justify">


        <h3 class="section-title font-alt">Online Consultation Facilities
          <span>We are Open for You</span>
        </h3>
        <div class="row align-justify">
          <div class="col-lg-3 mt-10 mt-md-30">
            <div class="exp-media">
              <img src="../../assets/images/portfolio/online.png" alt="Online Consultation Facilities-2">
            </div>
          </div>
          <div class="col-lg-9 align-justify">
            <p>Ayurveda is considered by many scholars to be the oldest healing science. In Sanskrit,
              Ayurveda means “The Science of Life.” Ayurvedic knowledge originated in India more than
              5,000 years ago and is often called the “Mother of All Healing.” It stems from the ancient
              Vedic culture and was taught for many thousands of years in an oral tradition from
              accomplished masters to their disciples.</p>
            <br>
            <p> Some of this knowledge was set to print a few thousand years ago, but much of it is
              inaccessible. The principles of many of the natural healing systems now familiar in the West
              have their roots in Ayurveda, including Homeopathy and Polarity Therapy. </p>

            <router-link to="/#register" class="button small mt-10 mb-20">Register</router-link>
          </div>

        </div>
        <div class="clearfix"></div>
        <h3 class="serv-title font-alt">The Strategy : Your Constitution and Its Inner Balance</h3>

        <p> Ayurveda places great emphasis on prevention and encourages the maintenance of health through close
          attention to balance in one’s life, right thinking, diet, lifestyle and the use of herbs. Knowledge
          of Ayurveda enables one to understand how to create this balance of body, mind and consciousness
          according to one’s own individual constitution and how to make lifestyle changes to bring about and
          maintain this balance.</p>
        <p>Just as everyone has a unique fingerprint, each person has a particular pattern of energy—an
          individual combination of physical, mental and emotional characteristics—which comprises their own
          constitution. This constitution is determined at conception by a number of factors and remains the
          same throughout one’s life.
        </p>
        <p>
          Many factors, both internal and external, act upon us to disturb this balance and are reflected as a
          change in one’s constitution from the balanced state. Examples of these emotional and physical
          stresses include one’s emotional state, diet and food choices, seasons and weather, physical trauma,
          work and family relationships. Once these factors are understood, one can take appropriate actions
          to nullify or minimize their effects or eliminate the causes of imbalance and re-establish one’s
          original constitution. Balance is the natural order; imbalance is disorder. Health is order; disease
          is disorder. Within the body there is a constant interaction between order and disorder. When one
          understands the nature and structure of disorder, one can re-establish order.
        </p>
        <h3 class="serv-title font-alt">Balancing the Three Principle Energies of the Body</h3>
        <p>Ayurveda identifies three basic types of energy or functional principles that are present in everyone
          and everything. Since there are no single words in English that convey these concepts, we use the
          original Sanskrit words vata, pitta and kapha. These principles can be related to the basic biology
          of the body.
        </p>
        <p> Energy is required to create movement so that fluids and nutrients get to the cells, enabling the
          body to function. Energy is also required to metabolize the nutrients in the cells, and is called
          for to lubricate and maintain the structure of the cell. Vata is the energy of movement; pitta is
          the energy of digestion or metabolism and kapha, the energy of lubrication and structure. All people
          have the qualities of vata, pitta and kapha, but one is usually primary, one secondary and the third
          is usually least prominent. The cause of disease in Ayurveda is viewed as a lack of proper cellular
          function due to an excess or deficiency of vata, pitta or kapha. Disease can also be caused by the
          presence of toxins.</p>
        <p> In Ayurveda, body, mind and consciousness work together in maintaining balance. They are simply
          viewed as different facets of one’s being. To learn how to balance the body, mind and consciousness
          requires an understanding of how vata, pitta and kapha work together. According to Ayurvedic
          philosophy the entire cosmos is an interplay of the energies of the five great elements—Space, Air,
          Fire, Water and Earth. Vata, pitta and kapha are combinations and permutations of these five
          elements that manifest as patterns present in all creation. In the physical body, vata is the subtle
          energy of movement, pitta the energy of digestion and metabolism, and kapha the energy that forms
          the body’s structure.</p>
        <h3 class="serv-title font-alt">Vata is the subtle energy associated with movement</h3>

        <p> — composed of Space and Air. It governs breathing, blinking, muscle and tissue movement, pulsation
          of the heart, and all movements in the cytoplasm and cell membranes. In balance, vata promotes
          creativity and flexibility. Out of balance, vata produces fear and anxiety.</p>

        <h3 class="serv-title font-alt">Pitta expresses as the body’s metabolic system</h3>

        <p> — made up of Fire and Water. It governs digestion, absorption, assimilation, nutrition, metabolism
          and body temperature. In balance, pitta promotes understanding and intelligence. Out of balance,
          pitta arouses anger, hatred and jealousy.
        </p>
        <h3 class="serv-title font-alt">Kapha is the energy that forms the body’s structure </h3>

        <p> — bones, muscles, tendons — and provides the “glue” that holds the cells together, formed from Earth
          and Water. Kapha supplies the water for all bodily parts and systems. It lubricates joints,
          moisturizes the skin, and maintains immunity. In balance, kapha is expressed as love, calmness and
          forgiveness. Out of balance, it leads to attachment, greed and envy.
          Life presents us with many challenges and opportunities. Although there is much over which we have
          little control, we do have the power to decide about some things, such as diet and lifestyle. To
          maintain balance and health, it is important to pay attention to these decisions. Diet and lifestyle
          appropriate to one’s individual constitution strengthen the body, mind and consciousness.

        </p>

        <h3 class="serv-title font-alt">Ayurveda as a Complementary System of Healing</h3>

        <p>The basic difference between Ayurveda and Western allopathic medicine is important to understand.
          Western allopathic medicine currently tends to focus on symptomatology and disease, and primarily
          uses drugs and surgery to rid the body of pathogens or diseased tissue. Many lives have been saved
          by this approach. In fact, surgery is encompassed by Ayurveda. However, drugs, because of their
          toxicity, often weaken the body. Ayurveda does not focus on disease. Rather, Ayurveda maintains that
          all life must be supported by energy in balance. When there is minimal stress and the flow of energy
          within a person is balanced, the body’s natural defense systems will be strong and can more easily
          defend against disease.
        </p>
        <p>It must be emphasized that Ayurveda is not a substitute for Western allopathic medicine. There are
          many instances when the disease process and acute conditions can best be treated with drugs or
          surgery. Ayurveda can be used in conjunction with Western medicine to make a person stronger and
          less likely to be afflicted with disease and/or to rebuild the body after being treated with drugs
          or surgery.</p>
        <p> We all have times when we don’t feel well and recognize that we’re out of balance. Sometimes we go
          to the doctor only to be told there is nothing wrong. What is actually occurring is that this
          imbalance has not yet become recognizable as a disease. Yet it is serious enough to make us notice
          our discomfort. We may start to wonder whether it is just our imagination. We may also begin to
          consider alternative measures and actively seek to create balance in our body, mind and
          consciousness.
        </p>

        <h3 class="serv-title font-alt">Evaluation and Treatment of Imbalances</h3>
        <p>Ayurveda encompasses various techniques for assessing health. The practitioner carefully evaluates
          key signs and symptoms of illness, especially in relation to the origin and cause of an imbalance.
          They also consider the patient’s suitability for various treatments. The practitioner arrives at
          diagnosis through direct questioning, observation and a physical exam, as well as inference. Basic
          techniques such taking the pulse, observing the tongue, eyes and physical form; and listening to the
          tone of the voice are employed during an assessment.</p>
        <p> Palliative and cleansing measures, when appropriate, can be used to help eliminate an imbalance
          along with suggestions for eliminating or managing the causes of the imbalance. Recommendations may
          include the implementation of lifestyle changes; starting and maintaining a suggested diet; and the
          use of herbs. In some cases, participating in a cleansing program, called panchakarma, is suggested
          to help the body rid itself of accumulated toxins to gain more benefit from the various suggested
          measures of treatment.</p>
        <p> In summary, Ayurveda addresses all aspects of life — the body, mind and spirit. It recognizes that
          each of us is unique, each responds differently to the many aspects of life, each possesses
          different strengths and weaknesses. Through insight, understanding and experience Ayurveda presents
          a vast wealth of information on the relationships between causes and their effects, both immediate
          and subtle, for each unique individual.</p>

        <Reference/>

      </div>


    </section>
    <!-- /page section -->

    <!-- footer -->

    <Footer/>

    <!-- / footer -->

    <!-- scroll-top -->

    <Scrolltop/>

    <!-- / scroll-top -->

  </div>

</template>

<script>
import Reference from "@/components/external/reference";
import Header from "@/components/Header";
import SiteTopPanel from "@/components/site-top-panel";
import Footer from "@/components/Footer";
import Scrolltop from "@/components/Scrolltop";


export default {
  name: "Online-consultation",
  components: {
    Scrolltop,
    Footer,
    Reference,
    Header,
    SiteTopPanel
  }
}
</script>

<style scoped>

</style>